import AutoBind from "auto-bind";
import each from "lodash/each";
import Detection from "./classes/Detection";
import Canvas from "./components/Canvas";
import Preloader from "./components/Preloader";
import VideoPlay from "./components/Video";
import Case from "./pages/Case";
import Home from "./pages/Home";
import { copyText, mapEach } from "./utils/dom";
class App {
  constructor() {
    AutoBind(this);

    this.url = window.location.pathname;

    this.mouse = {
      x: window.innerWidth / 2,
      y: window.innerHeight / 2,
    };

    this.createCase();
    this.createHome();
    this.createCanvas();
    this.createPlayers();
    this.createPreloader();
    this.createShareButton();

    this.pages = {
      "/": this.home,
      "/case": this.case,
    };

    if (this.url.indexOf("/case") > -1) {
      this.page = this.case;
      this.page.onResize();
    } else {
      this.page = this.pages[this.url];
    }

    this.page.show(this.url);

    this.addEventListeners();
    this.addLinksEventsListeners();
    this.update();

    this.onResize();
  }

  createHome() {
    this.home = new Home();
  }

  createCase() {
    this.case = new Case();
  }

  createCanvas() {
    this.canvas = new Canvas();
  }

  createPlayers() {
    this.video = new VideoPlay({
      element: "[data-yt-player]",
    });

    mapEach(document.querySelectorAll("[data-play-youtube]"), (element) => {
      element.addEventListener("click", () => {
        const id = element.getAttribute("data-play-youtube");
        this.video.loadVideo(id, element);
      });
    });
  }

  createShareButton() {
    const share = document.querySelectorAll("[data-share-button]");
    const url = window.location.href;

    each(share, (element) => {
      const initialInnerText = element.innerText;
      element.addEventListener("click", () => {
        copyText(url);

        element.innerText = "Link Copied";

        setTimeout(() => {
          element.innerText = initialInnerText;
        }, 1000);
      });
    });
  }

  createPreloader() {
    this.preloader = new Preloader({
      canvas: this.canvas,
    });

    this.preloader.once("completed", this.onPreloaded.bind(this));
  }

  onPreloaded() {
    this.onResize();

    this.canvas.onPreloaded();

    this.page.show();
  }

  async onChange({ push = true, url = null }) {
    url = url.replace(window.location.origin, "");

    if (this.isFetching || this.url === url) return;

    this.isFetching = true;

    this.url = url;

    if (this.canvas) {
      this.canvas.onChange(this.url);
    }

    await this.page.hide();

    if (push) {
      window.history.pushState({}, document.title, url);
    }

    if (this.url.indexOf("/case") > -1) {
      this.page = this.case;
    } else {
      this.page = this.pages[this.url];
    }

    await this.page.show(this.url);

    this.isFetching = false;
  }

  /**
   * Events
   */

  onResize() {
    if (this.home) {
      this.home.onResize();
    }

    if (this.case) {
      this.case.onResize();
    }

    if (this.canvas && this.canvas.onResize) {
      this.canvas.onResize();
    }
  }

  onWheel(event) {
    if (this.page && this.page.onWheel) {
      this.page.onWheel(event);
    }
  }

  onPopState() {
    this.onChange({
      url: window.location.pathname,
      push: false,
    });
  }

  onTouchDown(event) {
    event.stopPropagation();

    if (!Detection.isMobile() && event.target.tagName === "A") return;

    this.mouse.x = event.touches ? event.touches[0].clientX : event.clientX;
    this.mouse.y = event.touches ? event.touches[0].clientY : event.clientY;

    if (this.page && this.page.onTouchDown) {
      this.page.onTouchDown(event);
    }
  }

  onTouchMove(event) {
    event.stopPropagation();

    this.mouse.x = event.touches ? event.touches[0].clientX : event.clientX;
    this.mouse.y = event.touches ? event.touches[0].clientY : event.clientY;

    if (this.page && this.page.onTouchMove) {
      this.page.onTouchMove(event);
    }
  }

  onTouchUp(event) {
    event.stopPropagation();

    this.mouse.x = event.changedTouches
      ? event.changedTouches[0].clientX
      : event.clientX;
    this.mouse.y = event.changedTouches
      ? event.changedTouches[0].clientY
      : event.clientY;

    if (this.page && this.page.onTouchUp) {
      this.page.onTouchUp(event);
    }
  }

  /**
   * Loop
   */
  update() {
    if (this.page) {
      this.page.update();
    }

    if (this.canvas && this.canvas.update) {
      this.canvas.update(this.page.scroll);
    }

    window.requestAnimationFrame(this.update);
  }

  /**
   * Listeners
   */
  addLinksEventsListeners() {
    const links = document.querySelectorAll("a");

    each(links, (link) => {
      const isLocal = link.href.indexOf(window.location.origin) > -1;

      if (isLocal) {
        link.onclick = (event) => {
          event.preventDefault();

          this.onChange({
            url: link.href,
          });
        };
      } else if (
        link.href.indexOf("mailto") === -1 &&
        link.href.indexOf("tel") === -1
      ) {
        link.rel = "noopener";
        link.target = "_blank";
      }
    });
  }

  addEventListeners() {
    window.addEventListener("resize", this.onResize, { passive: true });
    window.addEventListener("popstate", this.onPopState, { passive: true });

    window.addEventListener("mousewheel", this.onWheel, { passive: true });
    window.addEventListener("wheel", this.onWheel, { passive: true });

    window.addEventListener("mousedown", this.onTouchDown, { passive: true });
    window.addEventListener("mousemove", this.onTouchMove, { passive: true });
    window.addEventListener("mouseup", this.onTouchUp, { passive: true });

    window.addEventListener("touchstart", this.onTouchDown, { passive: true });
    window.addEventListener("touchmove", this.onTouchMove, { passive: true });
    window.addEventListener("touchend", this.onTouchUp, { passive: true });
  }
}

new App();
