import gsap from "gsap/all";
import Page from "../../classes/Page";
import { delay } from "../../utils/math";
import { split } from "../../utils/text";
import Splitting from "splitting";
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";

export default class extends Page {
  constructor() {
    super({
      classes: {
        active: "home--active",
      },
      element: ".home",
      elements: {
        wrapper: ".home__wrapper",
        opacity: "[data-opacity]",
        title1: ".home__title--first",
        title2: ".home__title--second",
      },
    });

    Splitting({ target: this.elements.title1, by: "words" });
    Splitting({ target: this.elements.title2, by: "words" });
  }

  show() {
    this.element.classList.add(this.classes.active);

    const { opacity } = this.elements;

    gsap.from([opacity], {
      opacity: 0,
      duration: 0.2,
      delay: 3,
      ease: "Power3.easeOut",
    });

    gsap.from("canvas", {
      duration: 0.2,
      opacity: 0,
      y: 100,
      delay: 3,
      ease: "Power3.easeOut",
    });

    gsap.from(".home__title--first .word", {
      duration: 0.8,
      yPercent: -100,
      rotation: -4,
      stagger: 0.1,
      delay: 3,
      ease: "Power3.easeOut",
    });

    gsap.from(".home__title--second .word", {
      duration: 0.8,
      yPercent: 100,
      rotation: -4,
      stagger: 0.1,
      delay: 3,
      ease: "Power3.easeOut",
    });

    return super.show();
  }

  async hide() {
    this.element.classList.remove(this.classes.active);

    await delay(400);

    return super.hide();
  }

  onResize() {
    super.onResize();
  }

  update() {
    super.update();
  }
}
