import Player from "@vimeo/player";
import ModalTransition from "../animations/ModalTransition";
import Component from "../classes/Component";

export default class VideoPlay extends Component {
  constructor({ element }) {
    super({
      element,
      elements: {
        player: "#yt-player",
        close: ".c-modal__close",
      },
    });

    this.loadYTVideo();
    this.createTransition();
  }

  createTransition() {
    this.transition = new ModalTransition();
  }

  loadYTVideo() {
    this.player = new Player(this.elements.player, {
      id: "671957925",
      width: 640,
      height: 360,
      color: "white",
    });
  }

  playVideo() {
    this.player.play();
  }

  async stopVideo() {
    await this.player.unload();
  }

  hideVideo() {
    this.stopVideo();
    this.element.classList.remove("active");
    this.transition.hide();
  }

  async loadVideo(id, playButton) {
    await this.player.loadVideo(id);
    await this.transition.show();

    playButton.blur();

    this.element.classList.add("active");
    this.playVideo();
    this.listenForClick();
  }

  async listenForClick() {
    this.elements.close.addEventListener("click", () => {
      this.transition.hide();
      this.element.classList.remove("active");
      this.stopVideo();
    });
  }
}
